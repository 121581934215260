/* eslint-disable react/jsx-pascal-case */
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useCallback, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Divider, Grid, useMediaQuery } from '@material-ui/core';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './hover.css';

import Footer from "./Footer";
import Nav_Button from "./Nav_botton";
import b from "../../src/image/Docs/doc.png";
import Article from "./Doc/Document";


import Sidebar from './Doc/Sidebar';


const useStyles = makeStyles((theme) => ({
    demo_fond: {
        backgroundColor: "#eeeeee",
    },
    avion: {
        backgroundColor: "#eeeeee"
    },
    titre: {
        textShadow: "2px 2px 4px black",
        fontFamily: "Lato, Courant, Regular",
        marginBottom: theme.spacing(5)
    },
    titre2: {
        marginTop: theme.spacing(4)
    },
    nav: {
        position: "absolute",
        zIndex: 1
    },
    hr: {
        width: "50%",
        border: "none",
        borderBottom: "1px solid black"
    },
    logo: {
        width: "40%",
    },
    horizon: {
        width: "60%",
    },
    dsna: {
        width: "20%",
    },
    fond_logo: {
        backgroundColor: "white",
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2),
        textAlign: "center"
    },
    font: {
        fontFamily: "Lato, Courant, Regular",
    },
    utilise: {
        marginTop: theme.spacing(5),
    },
    a: {
        color: "black",
        textDecoration: "none"
    },
    mainGrid: {
        marginTop: theme.spacing(3),
    },

}));

/////////////////////////////////////////////////////////////////////////////////////////////////////////
//Partie à modifier si l'on veut rajouter un nouveau document, elle permet de faire apparître le document dans la sidebar à gauche

const sidebar: any = {
    firstStep: [
    ],
    Api: [
    ],
    Algo: [
    ]
};

//Permet de savoir sur quel élément de la sidebar 
var current = "";

var ajout: string[] = [];

//Permet de récupérer la valeur makdown de chaque fichier
const docs = {
    "firstStep": ajout,
    "API": ajout,
    "Algo": ajout
}
async function ajoutMarkdown(post: any, indexe: any, type: any) {
    await fetch(post).then(res => res.text()).then(text => {

        ajout.push(text)
        if (type === "firstStep") {
            sidebar.firstStep[indexe].url = text

        }
        else if (type === "API") {
            sidebar.Api[indexe].url = text
        }
        else if (type === "Algo") {
            sidebar.Algo[indexe].url = text
        }


    })
    return new Promise(resolve =>
        resolve("ok"));

}

const importAll = (r: any) => {
    for (let index = 0; index < r.keys().length; index++) {
        const element = r.keys().sort()[index];
        if (element.indexOf("_") !== -1)
            var test = element.substr(element.indexOf("_"), element.lastIndexOf(".") - element.indexOf("_")).split("_").join(" ")
        else
            test = element.substr(element.indexOf("/"), element.lastIndexOf(".") - element.indexOf("/")).split("/").join(" ")
        if (current === "firstStep") {
            sidebar.firstStep.push({ title: test, url: "" })

        }
        else if (current === "API") {
            sidebar.Api.push({ title: test, url: "" })
        }
        else if (current === "Algo") {
            sidebar.Algo.push({ title: test, url: "" })
        }
    }
    return r.keys().map(r)
};

async function addFirstStep() {
    current = "firstStep"
    const markdownFilesFirstStep = importAll(require.context('./Doc/First_steps/', false, /\.md$/))
        .sort()
        ;

    await Promise.all(markdownFilesFirstStep.map((file: any, indexe: number) => ajoutMarkdown(file.default, indexe, "firstStep")))
        .catch((err) => console.error(err));
    docs.firstStep = ajout;
    ajout = []
}


async function addAPI() {

    current = "API"
    const markdownFilesAPI = importAll(require.context('./Doc/API/', false, /\.md$/))
        .sort()
        ;

    await Promise.all(markdownFilesAPI.map((file: any, indexe: number) => ajoutMarkdown(file.default, indexe, "API")))
        .catch((err) => console.error(err));

    docs.API = ajout;
    ajout = [];
}

async function addAlgo() {

    current = "Algo"
    const markdownFilesAlgo = importAll(require.context('./Doc/Algo/', false, /\.md$/))
        .sort()
        ;

    await Promise.all(markdownFilesAlgo.map((file: any, indexe: number) => ajoutMarkdown(file.default, indexe, "Algo")))
        .catch((err) => console.error(err));


    docs.Algo = ajout;
    ajout = [];
}


const Doc = (props: any) => {
    const [change, SetChange] = React.useState(false)
    const [item, SetItem] = React.useState("")
    const classes = useStyles();
    const [ar, setArt] = React.useState("")
    const [load, setLoad] = React.useState(false)
    AOS.init();


    const getDocs = useCallback(async () => {
        setLoad(true)
        await addFirstStep();
        await addAPI();
        await addAlgo()
        setArt(sidebar.firstStep[0].url);
        setLoad(false)
    }, [])

    useEffect(() => {

        sidebar.firstStep = []
        sidebar.Api = []
        sidebar.Algo = []
        getDocs()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const theme = useTheme();
    const petit = useMediaQuery(theme.breakpoints.down('xs'));

    //Permet d'afficher un nouveau document
    const ChangeAffichage = (changement: boolean, art: string) => {
        SetChange(changement)
        SetItem(art)
    }


    return (
        <>
            <div className={classes.nav}>
                <Nav_Button />
            </div>


            <Grid container>
                <Grid item md={1}></Grid>
                <Grid item xs={12} md={3}>
                    <Grid container justify="center">
                        <div data-aos="fade-right">
                            <Typography align="center" variant="h2" className={classes.titre}>Documentation</Typography>
                            <Divider />
                        </div>
                    </Grid>

                </Grid>
                {petit === false &&
                    <Grid item className={classes.avion} xs={12} md={8}>
                        <br></br>
                        <Grid container justify="center">
                            <img src={b} alt="logo de la page de docs" height="100"></img>
                        </Grid>

                    </Grid>
                }

            </Grid>

            <Grid container>

                <Grid item md={2} className={classes.font}>

                </Grid>

                <Grid item xs={12} md={2} className={classes.font}>
                    {
                        load === false && ar !== "" &&
                        <Sidebar
                            archives={sidebar}
                            changement={ChangeAffichage}
                        />
                    }
                </Grid>
                <Grid item md={8} xs={12} className={classes.demo_fond}>
                    <Grid container>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={8}>
                            {/* Affichage par défaut du fichier d'introduction à 4CAST */}
                            {
                                change === false &&
                                <Article value={ar} changement={ChangeAffichage} />
                            }


                            {
                                change === true &&
                                <Article value={item} changement={ChangeAffichage} />
                            }

                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>


                </Grid>
            </Grid>
            <Footer></Footer>
        </>
    );
}

export default Doc;

