import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Markdown from './Markdown';

const useStyles = makeStyles((theme) => ({
    markdown: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
    },
}));

interface MainProps {
    posts: Array<string>;
    title: string;
}

export default function Main(props: MainProps) {
    const classes = useStyles();

    const { posts } = props;

    return (
        <Grid item xs={12} md={12}>
            <Divider />
            {
                posts.map((post) => (
                    <Markdown className={classes.markdown} key={post.substring(0, 25)}>
                        {post}
                    </Markdown>
                ))}
        </Grid>
    );
}