import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Paper, { PaperProps } from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import React from 'react';
import Draggable from 'react-draggable';
import * as Yup from 'yup';
import CheckIcon from '@material-ui/icons/Check';


// Tableau des éléments à placer dans le dropdown concernant le motif de la demande
const currencies = [
    {
        value: "J'ai une question sur le fonctionnement de 4CAST",
        label: "J'ai une question sur le fonctionnement de 4CAST",
    },
    {
        value: 'Je veux proposer une amélioration',
        label: 'Je veux proposer une amélioration',
    },
    {
        value: "Je voudrais un accès à l'API",
        label: "Je voudrais un accès à l'API",
    },
    {
        value: 'Autre',
        label: 'Autre',
    },
];


const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    titre: {
        marginTop: theme.spacing(6),
        color: "black",
    },
    container: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(4),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: "#F8A055",
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(4),
    },
    fond: {
        backgroundColor: "white",
        boxShadow: "4px 4px 4px 4px black",
        borderRadius: "10px"

    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    erreur: {
        margin: theme.spacing(1),
        backgroundColor: "red",
        textAlign: "center"
    },
    valid: {
        backgroundColor: "green",
        margin: theme.spacing(1),
        textAlign: "center"

    },
    boutton: {
        marginBottom: theme.spacing(4),
        backgroundColor: "#4897D8",

    },
    successMessage: { color: 'green' },
    errorMessage: { color: 'red' },
}));


interface ContactFormI {
    email: string
    choix: string
    nom: string,
    prenom: string,
    center: string,
    message: string,
}



function PaperComponent(props: PaperProps) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}

// Créé le formulaire de contact
const ContactForm: React.FC<{}> = (props) => {
    const classes = useStyles();
    const [currency, setCurrency] = React.useState('Je veux proposer une amélioration');
    const [open_valid, setOpenValid] = React.useState(false);

    const handleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrency(event.target.value);
    };

    const handleClickOpenValid = () => {
        setOpenValid(true);
    }

    const handleCloseValid = () => {
        setOpenValid(false);
    }


    const modal_valid =
        <Dialog
            open={open_valid}
            onClose={handleCloseValid}
            PaperComponent={PaperComponent}
            aria-labelledby="draggable-dialog-title"
        >
            <DialogTitle style={{ cursor: 'move' }} >
                <Grid container spacing={3}>
                    <Grid item xs={5}></Grid>
                    <Grid item xs={2}>
                        <Avatar className={classes.valid}>
                            <CheckIcon />
                        </Avatar>
                    </Grid>
                    <Grid item xs={5}></Grid>
                </Grid>

            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Votre demande a bien été envoyé ! Une réponse vous sera envoyé sous quelques jours.
                </DialogContentText>
            </DialogContent>
            <DialogActions>

            </DialogActions>
        </Dialog>

    return (
        <>
            <div className={classes.fond}>
                <Formik
                    initialValues={{
                        center: '',
                        choix: currency,
                        nom: '',
                        prenom: '',
                        email: '',
                        message: '',
                    }}
                    onSubmit={(values: ContactFormI, { resetForm }) => {
                        //prépare l'objet pour l'envoyer à l'api
                        const data = {
                            name: values.nom,
                            center: values.center,
                            choix: values.choix,
                            email: values.email,
                            firstname: values.prenom,
                            message: values.message

                        };

                        //envoie à l'api
                        axios.post('https://6c18x9upo6.execute-api.eu-west-1.amazonaws.com/prod/SendMail', JSON.stringify(data), { headers: { 'Content-Type': 'application/json', 'x-api-key': 'FxZKBllAj34FUIdIiKS9f3an26x9UAVI7ZPMeiag' } }).then(
                            res => {

                            }
                        )
                        handleClickOpenValid()
                        resetForm()
                    }}
                    validationSchema={Yup.object().shape({
                        email: Yup.string()
                            .email()
                            .required('Entrez un email valide'),
                        center: Yup.string().required('Merci de saisir votre centre'),
                        choix: Yup.string().required('Merci de choisir une des valeurs'),
                        nom: Yup.string().required("Merci d'indiquer votre nom"),
                        prenom: Yup.string().required("Merci d'indiquer votre prénom"),
                        message: Yup.string().required("Merci de rentrer votre message")

                    })}
                >

                    {(props: FormikProps<ContactFormI>) => {
                        const {
                            values,
                            touched,
                            errors,
                            handleBlur,
                            handleChange
                        } = props
                        return (
                            <Form>
                                <Container>

                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        select
                                        id="choix"
                                        value={currency}
                                        label="Objet de la demande"
                                        name="choix"
                                        autoFocus
                                        type="choix"
                                        SelectProps={{
                                            native: true,
                                        }}
                                        helperText={
                                            errors.choix && touched.choix
                                                ? errors.choix
                                                : ''
                                        }
                                        error={
                                            errors.choix && touched.choix
                                                ? true
                                                : false
                                        }
                                        onChange={handleChange2}

                                    >
                                        {currencies.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </TextField>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        value={values.email}
                                        label="Adresse email"
                                        name="email"
                                        autoComplete="email"
                                        type="email"
                                        helperText={
                                            errors.email && touched.email
                                                ? errors.email
                                                : ''
                                        }
                                        error={
                                            errors.email && touched.email
                                                ? true
                                                : false
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}


                                    />
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="nom"
                                                label="Nom"
                                                value={values.nom}
                                                id="nom"
                                                helperText={
                                                    errors.nom && touched.nom
                                                        ? errors.nom
                                                        : ''
                                                }
                                                error={
                                                    errors.nom && touched.nom
                                                        ? true
                                                        : false
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="prenom"
                                                label="Prénom"
                                                value={values.prenom}
                                                id="prenom"
                                                autoComplete="prenom"
                                                helperText={
                                                    errors.prenom &&
                                                        touched.prenom
                                                        ? errors.prenom
                                                        : ''
                                                }
                                                error={
                                                    errors.prenom &&
                                                        touched.prenom
                                                        ? true
                                                        : false
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />

                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={3}></Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                variant="outlined"
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="center"
                                                value={values.center}
                                                label="Centre"
                                                name="center"
                                                autoComplete="centre"
                                                type="text"
                                                helperText={
                                                    errors.center && touched.center
                                                        ? errors.center
                                                        : ''
                                                }
                                                error={
                                                    errors.center && touched.center
                                                        ? true
                                                        : false
                                                }
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />


                                        </Grid>
                                        <Grid item xs={3}></Grid>
                                    </Grid>

                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        fullWidth
                                        multiline
                                        rows={10}
                                        id="message"
                                        value={values.message}
                                        label="Message"
                                        name="message"
                                        type="text"
                                        helperText={
                                            errors.message && touched.message
                                                ? errors.message
                                                : ''
                                        }
                                        error={
                                            errors.message && touched.message
                                                ? true
                                                : false
                                        }
                                        onChange={handleChange}
                                        onBlur={handleBlur}

                                    />

                                </Container>
                                <Container className={classes.container}>
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        className={classes.boutton}>
                                        Envoyer

                                    </Button>
                                </Container>
                            </Form>
                        )
                    }}
                </Formik>
                {modal_valid}
            </div >
        </>
    );
}

export default ContactForm;
