/* eslint-disable jsx-a11y/anchor-is-valid */
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import gitlab from "../image/Home/gitlab.png"

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#F8A055",
    paddingTop: '16px',
    overflowX: 'hidden'
  },
  footerSections: {
    marginLeft: '10 16px'
  },
  subFooter: {
    backgroundColor: 'rgba(0, 0, 0, 0.15)',
    padding: '8px 16px 8px 16px',
    marginTop: '8px'
  },
  footerText: {
    color: '#fff',
    fontSize: '18px',
    lineHeight: 1.5,
    textAlign: "center"
  },
  invertedBtnDark: {
    color: '#fff',
    backgroundColor: 'transparent',
    border: '2px #fff solid',
    boxShadow: 'none',
    margin: '8px'
  },
  white: {
    color: '#ffffff',
    textAlign: "center",
    textDecoration: "none"
  },
  white_top: {
    color: '#ffffff',
    textAlign: "center",
    marginBottom: 15
  },
  flexContainer: {
    display: 'flex'
  }
}));

// Création du footer du site

function Footer() {
  const classes = useStyles();
  const currentYear = new Date().getFullYear()
  return (
    <div className={classes.root}>
      <Grid
        container
        className={classes.footerText + " " + classes.footerSections}
      >
        <Grid item xs={12} sm={4}>
          <ul style={{ listStyle: 'none', margin: 0 }}>
            <li className={classes.white_top}>
              <a className={classes.white_top} style={{ fontWeight: 400 }}>
                <b>Communauté</b>
              </a>
            </li>
            <li><a href="https://twitter.com/4cast_atfcm?lang=fr" className={classes.white_top}><TwitterIcon style={{ fontWeight: 400 }} /></a></li>
            <li><a href="https://www.youtube.com/channel/UCLt5vm7-SiuXwIFgPIQt9Wg" className={classes.white_top}><YouTubeIcon style={{ fontWeight: 400 }} /></a></li>
            <li><a href="https://gitlab.asap.dsna.fr/4cast"><img src={gitlab} width="50" alt="Gitlab icon" /></a></li>

          </ul>
        </Grid>
        <Grid item xs={12} sm={4}>
          <ul style={{ listStyle: 'none', margin: 0 }}>
            <li className={classes.white_top}>
              <a className={classes.white_top} style={{ fontWeight: 400 }}>
                <b>Docs</b>
              </a>
            </li>
            <li>
              <a className={classes.white} href="#/Docs" onClick={() => { window.scrollTo(0, 0) }} style={{ fontWeight: 400 }}>
                Use API's
              </a>
            </li>
          </ul>
        </Grid>

        <Grid item xs={12} sm={4}>
          <ul style={{ listStyle: 'none', margin: 0 }}>
            <li className={classes.white_top}>

              <a className={classes.white_top} style={{ fontWeight: 400 }}>
                <b>More</b>
              </a>
            </li>
            <li>

              <a className={classes.white} href="#/Blog" onClick={() => { window.scrollTo(0, 0) }} style={{ fontWeight: 400 }}>
                Blog
              </a>
            </li>
            <li>

              <a className={classes.white} href="#/About&Contact" onClick={() => { window.scrollTo(0, 0) }} style={{ fontWeight: 400 }}>
                Contact
              </a>
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid className={classes.subFooter} item xs={12}>
        <Typography
          className={classes.white}

        >
          Copyright © {currentYear} DSNA/DGAC
        </Typography>
      </Grid>
    </div>
  )
}
export default Footer;