import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Markdown from 'markdown-to-jsx';
import { Grid } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    listItem: {
        marginTop: theme.spacing(1),
    },
    fond: {
        backgroundColor: "#D3D3D3"
    }
}));

// Permet de donner un comportement à une balise du markdown - On peut créer cette balise dans options 
function MarkdownListItem(props: any) {
    const classes = useStyles();
    return (
        <li className={classes.listItem}>
            <Typography component="span" {...props} />
        </li>
    );
}

function MarkdownB(props: any) {
    return (
        <span style={{ backgroundColor: "#D3D3D3" }}{...props}>
        </span>
    );
}

function MarkdownCadre(props: any) {
    const theme = useTheme()
    return (
        <div style={{ backgroundColor: "white", border: "solid", marginBottom: theme.spacing(4) }}>
            <Grid container>
                <Grid item md={1}></Grid>
                <span {...props}></span>
            </Grid>
        </div>
    );
}

function MarkdownCommande(props: any) {
    const theme = useTheme()
    return (
        <div style={{ backgroundColor: "white", border: "solid", marginBottom: theme.spacing(4) }}>
            <Grid container>
                <Grid item md={1}></Grid>
                <span {...props}></span>
            </Grid>
        </div>
    );
}

// Options de personnalisation du markdown - On peut ici définir de nouvelles balises
const options = {
    overrides: {
        h1: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'h4',
            },
        },
        h2: {
            component: Typography,
            props: { gutterBottom: true, variant: 'h5' },
        },
        h3: {
            component: Typography,
            props: { gutterBottom: true, variant: 'h6' },
        },
        h4: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'caption',
                paragraph: true,
            },
        },
        h6: {
            component: Typography,
            props: {
                gutterBottom: true,
                variant: 'caption',
                paragraph: true,
            },
        },
        p: {
            component: Typography,
            props: { paragraph: true },
        },
        a: { component: Link },
        li: {
            component: MarkdownListItem,
        },
        important: {
            component: MarkdownB,
        },
        cadre: {
            component: MarkdownCadre,
        },
        commande: {
            component: MarkdownCommande,
        }
    },
};

// Affichage du markdown
export default function MD(props: any) {
    return <Markdown options={options} {...props} />;
}