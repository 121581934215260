/* eslint-disable react/jsx-pascal-case */
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { PropsWithChildren, useCallback, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Grid, useMediaQuery } from '@material-ui/core';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './hover.css';


import Main from './Blog/Main';
import Footer from "./Footer";
import Nav_Button from "./Nav_botton";
import b from "../../src/image/Blog/blog.png";
import Article from "./Blog/Article";
//@ts-ignore
import { TwitterTimelineEmbed } from 'react-twitter-embed';


import Sidebar from './Blog/Sidebar';

const useStyles = makeStyles((theme) => ({
    demo_fond: {
        backgroundColor: "#eeeeee",
    },
    avion: {
        backgroundColor: "#eeeeee"
    },
    titre: {
        textShadow: "2px 2px 4px black",
        fontFamily: "Lato, Courant, Regular",
        marginBottom: theme.spacing(5)
    },
    titre2: {
        marginTop: theme.spacing(4)
    },
    nav: {
        position: "absolute",
        zIndex: 1
    },
    hr: {
        width: "50%",
        border: "none",
        borderBottom: "1px solid black"
    },
    logo: {
        width: "40%",
    },
    horizon: {
        width: "60%",
    },
    dsna: {
        width: "20%",
    },
    fond_logo: {
        backgroundColor: "white",
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2),
        textAlign: "center"
    },
    font: {
        fontFamily: "Lato, Courant, Regular",
    },
    taille: {
        minHeight: "100vh"
    },
    utilise: {
        marginTop: theme.spacing(5),
    },
    a: {
        color: "black",
        textDecoration: "none"
    },
    mainGrid: {
        marginTop: theme.spacing(3),
    },

}));

/////////////////////////////////////////////////////////////////////////////////////////////////////////
//Partie à modifier lors de l'ajout d'un article, le tableau permet un accès rapide aux 4 dernier articles parut
const sidebar: any = {
    archives: [
    ]
};
////////////////////////////////////////////////////////////////////////////////////////////////////////


interface Props extends PropsWithChildren<any> {
    article?: string
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////
//Partie à modifier, il faut ajouter le nouvel article au début afin de pouvoir être en cohérence avec la sidebar
////////////////////////////////////////////////////////////////////////////////////////////////////////

var res: string[] = [];
async function ajoutMarkdown(post: any, indexe: any) {
    await fetch(post).then(res => res.text()).then(text => {
        res.push(text)
        if (sidebar.archives.length > indexe) {
            sidebar.archives[indexe].url = text
        }

    })
    return new Promise(resolve =>
        resolve("ok"));

}

const importAll = (r: any) => {
    for (let index = 0; index < r.keys().length; index++) {
        const element = r.keys().sort()[index];
        var test = element.substr(element.indexOf("_"), element.lastIndexOf(".") - element.indexOf("_")).split("_").join(" ")
        sidebar.archives.push({ "title": test })
    }
    return r.keys().map(r)
};
const markdownFiles = importAll(require.context('./Blog/Article', false, /\.md$/))
    .sort()
    ;

const Blog = (props: Props) => {
    const [change, SetChange] = React.useState(false)
    const [item, SetItem] = React.useState("")
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [resultat, SetResultat] = React.useState(res)
    const [load, setLoad] = React.useState(false)
    const classes = useStyles();
    AOS.init();
    const theme = useTheme();
    const petit = useMediaQuery(theme.breakpoints.down('xs'));


    const getArticles = useCallback(async () => {
        setLoad(true)
        await Promise.all(markdownFiles.map((file: any, indexe: number) => ajoutMarkdown(file.default, indexe)))
            .catch((err) => console.error(err));
        SetResultat(res)
        setLoad(false)
    }, [])

    useEffect(() => {

        sidebar.archive = []
        res = []
        getArticles()


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const ChangeAffichage = (changement: boolean, art: string) => {
        SetChange(changement)
        SetItem(art)
    }


    return (
        <>
            <div className={classes.nav}>
                <Nav_Button />
            </div>


            <Grid container>
                <Grid item md={2}></Grid>
                <Grid item xs={12} md={2}>
                    <Grid container justify="center">
                        <div data-aos="fade-right">
                            <Typography align="center" variant="h2" className={classes.titre}>Blog</Typography>
                        </div>

                    </Grid>

                </Grid>
                {petit === false &&
                    <Grid item className={classes.avion} xs={12} md={8}>
                        <br></br>
                        <Grid container justify="center">
                            <img src={b} alt="logo de la page du Blog" height="100"></img>
                        </Grid>

                    </Grid>
                }


            </Grid>

            <Grid container className={classes.taille}>

                <Grid item md={2} className={classes.font}>

                </Grid>

                <Grid item xs={12} md={2} className={classes.font}>
                    {
                        load === false &&
                        <Sidebar
                            archives={sidebar.archives}
                            changement={ChangeAffichage}
                        />
                    }
                </Grid>
                <Grid item md={8} xs={12} className={classes.demo_fond}>
                    <Grid container>
                        <Grid item xs={2}></Grid>
                        <Grid item xs={8}>

                            {/* Affichage  de tous les articles avec les 250 premiers caractères*/}
                            {

                                change === false && load === false &&
                                <Main title="" posts={resultat} changement={ChangeAffichage} />
                            }
                            {/* Affichage  de l'article séléctionné*/}
                            {
                                change === true &&
                                <Article value={item} changement={ChangeAffichage} />
                            }

                        </Grid>
                        <Grid item xs={2}></Grid>
                    </Grid>
                    <Grid container justify="center" alignItems="center">

                        {petit &&
                            <TwitterTimelineEmbed
                                sourceType="profile"
                                screenName="4CAST_ATFCM"
                                options={{ height: 400, width: "500" }}
                            />
                        }
                    </Grid>



                </Grid>
            </Grid>
            <Footer></Footer>
        </>
    );
}

export default Blog;

