/* eslint-disable react/jsx-pascal-case */
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Grid, useMediaQuery } from '@material-ui/core';
import AOS from 'aos';
import 'aos/dist/aos.css';
import './hover.css';

import ContactForm from "../Page/ContactForm"
import Footer from "./Footer"
import Nav_Button from "./Nav_botton"

import CRNA from "../image/About/CRNA-E.jpg";
import ENAC from "../image/About/ENAC.jpg"
import DSNA from "../image/About/DSNA.png"
import HORIZON from "../image/About/HORIZON.png"

import decor from "../image/About/avion_papier.png"



const useStyles = makeStyles((theme) => ({
    demo_fond: {
        backgroundColor: "#eeeeee",
    },
    avion: {
        backgroundColor: "#eeeeee"
    },
    titre: {
        textShadow: "2px 2px 4px black",
        fontFamily: "Lato, Courant, Regular",
        marginBottom: theme.spacing(5)
    },
    titre2: {
        marginTop: theme.spacing(4)
    },
    nav: {
        position: "absolute",
        zIndex: 1
    },
    hr: {
        width: "50%",
        border: "none",
        borderBottom: "1px solid black"
    },
    logo: {
        width: "40%",
    },
    horizon: {
        width: "60%",
    },
    dsna: {
        width: "20%",
    },
    fond_logo: {
        backgroundColor: "white",
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2),
        textAlign: "center"
    },
    font: {
        fontFamily: "Lato, Courant, Regular",
    },
    utilise: {
        marginTop: theme.spacing(5),
    },
    a: {
        color: "black",
        textDecoration: "none"
    }
}));

// Page de contact
const Contact = () => {
    const classes = useStyles();
    AOS.init();
    const theme = useTheme();
    const petit = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <>
            <div className={classes.nav}>
                <Nav_Button />
            </div>

            {/* On conditionne l'affichage en fonction de la taille de l'écran */}
            {petit === false &&
                <>
                    <Grid container>
                        <Grid item xs={4} data-aos="fade-right">
                            <br></br>
                            <Typography align="center" variant="h2" className={classes.titre}>A propos</Typography>

                        </Grid>

                        <Grid item className={classes.avion} xs={8}>
                            <Grid container>
                                <Grid item md={5}></Grid>
                                <Grid item md={4} data-aos="fade-left">

                                    <img src={decor} alt="logo Avion" className={classes.horizon} />
                                </Grid>
                            </Grid>

                        </Grid>


                    </Grid>

                    <Grid container>

                        <Grid item xs={4} className={classes.font} data-aos="fade-right">
                            <hr className={classes.hr}></hr>

                            <Typography align="center" component="h4" variant="h5" className={classes.titre2}>Utile et pratique</Typography>
                            <Grid container>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={6} className={classes.fond_logo}>
                                    <img src={CRNA} alt="logo CRNA-E" className={classes.logo} />
                                    <img src={ENAC} alt="logo ENAC" className={classes.logo} />
                                </Grid>
                                <Grid item xs={3}></Grid>
                            </Grid>
                            <Typography align="center" >
                                Développé sur site par le CRNA-Est en partenariat avec l'ENAC
                            </Typography>

                            <Typography align="center"  >
                                2 Rue Alberto Santos Dumont <br /> 51100 Reims <br />
                                Piloté par M.LEPARLIER Thomas
                            </Typography>
                            <Grid container>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={8} className={classes.fond_logo} data-aos="fade-right">
                                    <img src={DSNA} alt="logo DSNA" className={classes.dsna} />
                                    <img src={HORIZON} alt="logo Horizon" className={classes.horizon} />
                                </Grid>
                                <Grid item xs={3}></Grid>
                            </Grid>
                            <Typography align="center"  >
                                Sous la direction de la <a href="https://asap.dsna.fr/" className={classes.a}>DSNA/DGAC</a> et sous l'impulsion de DSNA Horizon<br />
                                50 Rue Henry Farman <br /> 75015 Paris

                            </Typography>


                            <Typography align="center" className={classes.utilise}>
                                Ils utilisent déjà 4CAST :
                            </Typography>

                            <Grid container>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={6} className={classes.fond_logo}>
                                    <img src={CRNA} alt="logo CRNA-E" className={classes.logo} />
                                </Grid>
                                <Grid item xs={3}></Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={8} className={classes.demo_fond}>
                            <Typography align="center" variant="h2" className={classes.titre} data-aos="fade-left">
                                Nous contacter


                                <hr className={classes.hr}></hr>
                            </Typography>
                            <Grid container className={classes.utilise}>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={8} data-aos="fade-left">

                                    <ContactForm />
                                </Grid>
                                <Grid item xs={2}></Grid>

                            </Grid>
                        </Grid>


                    </Grid>
                </>
            }
            {petit &&
                <>
                    <Grid container>
                        <Grid item xs={12} data-aos="fade-right">
                            <br></br>
                            <Typography align="center" variant="h2" className={classes.titre}>A propos</Typography>

                        </Grid>

                    </Grid>

                    <Grid container>

                        <Grid item xs={12} className={classes.font} data-aos="fade-right">
                            <hr className={classes.hr}></hr>

                            <Typography align="center" component="h4" variant="h5" className={classes.titre2}>Utile et pratique</Typography>
                            <Grid container>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={6} className={classes.fond_logo}>
                                    <img src={CRNA} alt="logo CRNA-E" className={classes.logo} />
                                    <img src={ENAC} alt="logo ENAC" className={classes.logo} />
                                </Grid>
                                <Grid item xs={3}></Grid>
                            </Grid>
                            <Typography align="center" >
                                Développé sur site par le CRNA-Est en partenariat avec l'ENAC
                            </Typography>

                            <Typography align="center"  >
                                2 Rue Alberto Santos Dumont <br /> 51100 Reims <br />
                                Piloté par M.LEPARLIER Thomas
                            </Typography>
                            <Grid container>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={8} className={classes.fond_logo} data-aos="fade-right">
                                    <img src={DSNA} alt="logo DSNA" className={classes.dsna} />
                                    <img src={HORIZON} alt="logo Horizon" className={classes.horizon} />
                                </Grid>
                                <Grid item xs={3}></Grid>
                            </Grid>
                            <Typography align="center"  >
                                Sous la direction de la <a href="https://asap.dsna.fr/" className={classes.a}>DSNA/DGAC</a> et sous l'impulsion de DSNA Horizon<br />
                                50 Rue Henry Farman <br /> 75015 Paris

                            </Typography>


                            <Typography align="center" className={classes.utilise}>
                                Ils utilisent déjà 4CAST :
                            </Typography>

                            <Grid container>
                                <Grid item xs={3}></Grid>
                                <Grid item xs={6} className={classes.fond_logo}>
                                    <img src={CRNA} alt="logo CRNA-E" className={classes.logo} />
                                </Grid>
                                <Grid item xs={3}></Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} className={classes.demo_fond} data-aos="fade-left">


                            <Grid container justify="center" alignItems="center">

                                <img src={decor} alt="logo Avion" className={classes.horizon} />

                            </Grid>


                            <Typography align="center" variant="h2" className={classes.titre} >
                                Nous contacter


                                <hr className={classes.hr}></hr>
                            </Typography>
                            <Grid container className={classes.utilise}>
                                <ContactForm />
                            </Grid>
                        </Grid>


                    </Grid>
                </>
            }
            <Footer></Footer>
        </>
    );
}

export default Contact;
