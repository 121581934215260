import * as React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { PropsWithChildren } from 'react';
//@ts-ignore
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import { useMediaQuery } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    sidebarAboutBox: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[200],
    },
    sidebarSection: {
        marginTop: theme.spacing(3),
    },
    twitter: {
        maxHeight: "50px"
    },
    scroll_to_top: {
        position: "sticky",
        top: "20px"
    },
    touch: {
        "&:hover": { cursor: "pointer" },
    }
}));

interface SidebarProps extends PropsWithChildren<any> {
    archives: Array<{
        url: any;
        title: string;
    }>;
}

export default function Sidebar(props: SidebarProps) {
    const classes = useStyles();
    const { archives } = props;
    const theme = useTheme();
    const petit = useMediaQuery(theme.breakpoints.down('xs'));



    const click = (value: any) => {
        props.changement(true, value.url)
    }
    return (
        <Grid item xs={12} md={10} className={classes.scroll_to_top}>
            <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
                Articles récents
            </Typography>
            {archives.map((archive, indexe) => (

                <Link display="block" className={classes.touch} variant="body1" key={indexe} onClick={() => click(archive)} >
                    {archive.title}
                </Link>
            ))}
            {petit === false &&
                <>
                    <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
                        Social
                    </Typography>

                    <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName="4CAST_ATFCM"
                        options={{ height: 700, width: "500" }}
                    />
                </>
            }


        </Grid>
    );
}