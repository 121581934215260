import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import logo from "../image/logo.png"

import SpeedDial, { SpeedDialProps } from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import { useHistory } from 'react-router-dom';
import { PATHS } from '../routes';

import ContactMailIcon from '@material-ui/icons/ContactMail';

import CodeIcon from '@material-ui/icons/Code';
import SubjectIcon from '@material-ui/icons/Subject';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 30,
    backgroundColor: "#F8A055",
    paddingTop: '16px',
    overflowX: 'hidden'
  },
  scroll_to_top: {
    position: "fixed",
    width: "10px",
    height: "10px",
    bottom: "50px",
    right: "35px",
  },
  scroll_to_top_img: {
    position: 'absolute',
    width: "145px",
    backgroundColor: "white",
  },
  exampleWrapper: {
    position: 'relative',
  },
  radioGroup: {
    margin: theme.spacing(1, 0),
  },
  speedDial: {
    position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(1),
    }

  }
}));

// Permet de définir le boutton de navigation 
const Nav = () => {
  const classes = useStyles();
  const [direction] = React.useState<SpeedDialProps['direction']>('up');
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const handleClose = () => {
    setOpen(false);
  };

  const HomeClick = () => {
    history.push(PATHS.HOME);
    window.scrollTo(0, 0)
    handleClose();
  }

  const ContactClick = () => {
    history.push(PATHS.CONTACT);
    window.scrollTo(0, 0)
    handleClose();
  }
  const BlogClick = () => {
    history.push(PATHS.BLOG);
    window.scrollTo(0, 0)
    handleClose();
  }

  const DocsClick = () => {
    history.push(PATHS.DOCS);
    window.scrollTo(0, 0)
    handleClose();
  }

  const actions = [
    { icon: <HomeIcon />, name: 'Home', met: HomeClick },
    { icon: <CodeIcon />, name: 'Docs', met: DocsClick },
    { icon: <SubjectIcon />, name: 'Blog', met: BlogClick },
    { icon: <ContactMailIcon />, name: 'Contact Us', met: ContactClick },
  ];
  const handleOpen = () => {
    setOpen(true);
  };
  return (

    <div className={classes.scroll_to_top}>
      <SpeedDial
        ariaLabel="SpeedDial"
        className={classes.speedDial}
        hidden={false}
        icon={<img src={logo} alt="Retourner en haut" className={classes.scroll_to_top_img} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        direction={direction}
        color='secondary'
        FabProps={{ color: "default", size: "small" }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.met}
            className={classes.exampleWrapper}
          />
        ))}
      </SpeedDial>
    </div>
  )
}




export default Nav;
