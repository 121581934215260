/* eslint-disable react/jsx-pascal-case */
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../hover.css';
import Main from './Main_article'

const useStyles = makeStyles((theme) => ({
    demo_fond: {
        backgroundColor: "#eeeeee",
    },
    avion: {
        backgroundColor: "#eeeeee"
    },
    titre: {
        textShadow: "2px 2px 4px black",
        fontFamily: "Lato, Courant, Regular",
        marginBottom: theme.spacing(5)
    },
    titre2: {
        marginTop: theme.spacing(4)
    },
    nav: {
        position: "absolute",
        zIndex: 1
    },
    hr: {
        width: "50%",
        border: "none",
        borderBottom: "1px solid black"
    },
    logo: {
        width: "40%",
    },
    horizon: {
        width: "60%",
    },
    dsna: {
        width: "20%",
    },
    fond_logo: {
        backgroundColor: "white",
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(2),
        textAlign: "center"
    },
    font: {
        fontFamily: "Lato, Courant, Regular",
    },
    utilise: {
        marginTop: theme.spacing(5),
    },
    a: {
        color: "black",
        textDecoration: "none"
    },
    mainGrid: {
        marginTop: theme.spacing(3),
    },
    boutton: {
        marginBottom: theme.spacing(4),
        backgroundColor: "#4897D8",

    },
}));



const Article = (props: any) => {
    const classes = useStyles();
    AOS.init();
    var res: string[] = [];
    res.push(props.value)



    const Click = () => {
        window.scrollTo(0, 0)
        props.changement(false, "")
    }
    return (
        <>
            <Main title="Articles" posts={res} />
            <Grid container justify="flex-end">

                <Button className={classes.boutton} onClick={Click}>Découvrir plus d'article</Button>
            </Grid>
        </>
    );
}

export default Article;
