import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { PATHS } from './routes';

import Page404 from "./Page/404";
import Home from './Page/Home';
import Contact from './Page/Contact';
import Blog from './Page/Blog';
import Doc from './Page/Doc';



const Content: React.FC<{}> = () => {

  return (
    <Switch>
      <Route exact path={PATHS.HOME} component={Home} />
      <Route exact path={PATHS.CONTACT} component={Contact} />
      <Route exact path={PATHS.BLOG} component={Blog} props />
      <Route exact path={PATHS.DOCS} component={Doc} props />
      <Route component={Page404} />
    </Switch>
  );
};

export default Content;