import './App.css';
import Content from './Content';



const App: React.FC = () => {
  return (
    <div className="App" style={{ height: '100%' }}>
      <Content />
    </div>
  );
};

export default App;
