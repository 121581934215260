import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
//@ts-ignore


const useStyles = makeStyles((theme) => ({
    sidebarAboutBox: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[200],
    },
    sidebarSection: {
        marginTop: theme.spacing(3),
    },
    twitter: {
        maxHeight: "50px"
    },
    scroll_to_top: {
        position: "sticky",
        top: "20px"
    },
    black: {
        color: 'black',
        marginLeft: theme.spacing(4),
        textDecoration: "none",
        "&:hover": { cursor: "pointer" },
    },
    blue: {
        color: 'blue',
        marginLeft: theme.spacing(4),
        textDecoration: "none",
        "&:hover": { cursor: "pointer" },
    },
}));


export default function Sidebar(props: any) {
    const classes = useStyles();
    const { archives } = props;
    const [actif, setActif] = React.useState(archives.firstStep[0].title)

    const click = (value: any) => {
        setActif(value.title)
        window.scrollTo(0, 0)
        props.changement(true, value.url)

    }

    return (
        <Grid item xs={12} md={10} className={classes.scroll_to_top}>
            <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
                First steps
            </Typography>

            {archives.firstStep.map((archive: any, indexe: React.Key | null | undefined) => {

                return (
                    archive.title === actif ? <Link className={classes.blue} display="block" variant="body1" key={indexe} onClick={() => click(archive)} >
                        {archive.title}
                    </Link> : <Link className={classes.black} display="block" variant="body1" key={indexe} onClick={() => click(archive)} >
                        {archive.title}
                    </Link>



                )
            }


            )}

            <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
                API
            </Typography>

            {archives.Api.map((archive: any, indexe: React.Key | null | undefined) => (

                archive.title === actif ? <Link className={classes.blue} display="block" variant="body1" key={indexe} onClick={() => click(archive)} >
                    {archive?.title}
                </Link> : <Link className={classes.black} display="block" variant="body1" key={indexe} onClick={() => click(archive)} >
                    {archive?.title}
                </Link>
            ))}
            <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
                Algo
            </Typography>
            {archives.Algo.map((archive: any, indexe: React.Key | null | undefined) => (
                archive.title === actif ? <Link className={classes.blue} display="block" variant="body1" key={indexe} onClick={() => click(archive)} >
                    {archive.title}
                </Link> : <Link className={classes.black} display="block" variant="body1" key={indexe} onClick={() => click(archive)} >
                    {archive.title}
                </Link>
            ))}


        </Grid>
    );
}