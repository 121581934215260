/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react/jsx-pascal-case */
import { makeStyles, Theme, useTheme, withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Grid, Paper, Tooltip, useMediaQuery, Zoom } from '@material-ui/core';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { PATHS } from '../routes';
import FlightIcon from '@material-ui/icons/Flight';
import './hover.css';

import Footer from "./Footer"
import Nav_Button from "./Nav_botton"

import home from "../image/home.jpg"

import blog from '../image/Blog/blog.png'
import ecran from "../image/Home/screen.jpg"
import salle from "../image/Home/room.jpeg"
import book from "../image/Home/book.png"
import rocket from "../image/Home/rocket.png"
import demo from "../image/Home/Lea4Cast.gif";

import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  description: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: "6px 6px 0px black"
  },
  description2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: "0px 6px 0px black"
  },
  logo: {
    marginTop: theme.spacing(12),
  },
  texte: {
    height: '30vh',
  },
  container: {
    height: '50vh',
  },
  container1: {
    height: '60vh',
  },
  cont: {
    marginTop: theme.spacing(10),
  },
  cont_icon: {
    marginTop: theme.spacing(10),
    textAlign: "center",
  },
  fond: {
    backgroundImage: `url(${home})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover"
  },
  fond1: {
    backgroundImage: `url(${home})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  titre: {
    textAlign: "center"
  },
  image: {
    width: "100%",
    height: "100%",
    display: "block",
  },
  icone: {
    width: "30%",
    "&:hover": { cursor: "pointer" },
  },
  doc: {
    width: "50%",
    "&:hover": { cursor: "pointer" },
  },
  doc2: {
    width: "100%",
    "&:hover": { cursor: "pointer" },
  },
  icone2: {
    width: "70%",
    "&:hover": { cursor: "pointer" },
  },
  demo: {
    width: "50%",
  },
  demo2: {
    width: "100%",
  },
  cont_demo: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4)
  },
  titre_demo: {
    marginTop: theme.spacing(10),
    textAlign: "center",
  },
  avion: {
    transform: "rotate(55deg)",
  },
  nav: {
    position: "absolute",
    zIndex: 2
  }
}));

//Tooltip du gif
const HtmlTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

function Home() {
  const classes = useStyles();
  const history = useHistory()
  const theme = useTheme();

  AOS.init();
  const taille = useMediaQuery(theme.breakpoints.down('md'));
  const petit = useMediaQuery(theme.breakpoints.down('xs'));


  return (
    <>
      <div className={classes.nav}>
        <Nav_Button />
      </div>

      {/* On conditionne l'affichage en fonction de la taille de l'écran utilisé ou de la fenêtre */}
      {taille === false &&
        <Grid style={{ height: '100vh' }} className={classes.fond}>
          <br />
          <Grid container className={classes.container1}>
          </Grid>

          <Grid container className={classes.texte}>
            <Grid item sm={1} xs></Grid>
            <div data-aos="zoom-in" data-aos-anchor-placement="center-bottom">
              <Grid item sm={5} xs > <Paper className={classes.description}>
                <Typography component="h1" variant="h1">
                  4CAST
                </Typography>
                <Typography component="h3" variant="h6" align="center">
                  est un outil d’aide à la décision qui propose l’automatisation de l’élaboration de la configuration ATFCM dans les phases Post OPS, Stratégique, PreTACT et Tactique.

                </Typography></Paper></Grid>
            </div>
            <Grid item sm={4} xs></Grid>
            <Grid item sm={2} xs></Grid>
          </Grid>


        </Grid>
      }
      {taille &&

        <Grid style={{ height: '100vh' }} className={classes.fond1}>
          <Grid container className={classes.container}>
          </Grid>



          <Grid container className={classes.texte}>
            <div data-aos="zoom-in" data-aos-anchor-placement="center-bottom">
              <Grid item sm xs > <Paper className={classes.description2}>
                <Typography component="h1" variant="h3">
                  4CAST
                </Typography>
                <Typography component="h3" variant="h6" align="center">
                  est un outil d’aide à la décision qui propose l’automatisation de l’élaboration de la configuration ATFCM dans les phases Post OPS, Stratégique, PreTACT et Tactique.

                </Typography></Paper></Grid>
            </div>
          </Grid>


        </Grid>
      }
      <div data-aos="zoom-in" data-aos-anchor-placement="center-bottom">
        <Typography component="h1" variant="h3" className={classes.titre}>
          Découvrir 4CAST   <FlightIcon color="primary" fontSize="large" className={classes.avion} />
        </Typography>
      </div>
      {petit === false &&
        <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">
          <Grid container className={classes.cont}>
            <Grid item sm={2}></Grid>
            <Grid item sm={3}>
              <div data-aos="fade-right" data-aos-anchor-placement="center-bottom">
                <img src={salle} className={classes.image} alt="Photo de la salle" />
              </div></Grid>
            <Grid item sm={2}></Grid>
            <Grid item sm={3}>
              <div data-aos="fade-left" data-aos-anchor-placement="center-bottom">
                <img src={ecran} className={classes.image} alt="Capture de la configuration de 4CAST" />
              </div>
            </Grid>
            <Grid item sm={2}></Grid>

          </Grid>
        </div>
      }
      {petit &&
        <>
          <Grid container className={classes.cont} data-aos="fade-down" data-aos-anchor-placement="center-bottom">
            <Grid item sm={3}>
              <img src={ecran} className={classes.image} alt="Capture de la configuration de 4CAST" />
            </Grid>
          </Grid>
        </>
      }
      <div data-aos="fade-down" data-aos-anchor-placement="center-bottom">

        <Box display="flex" alignItems="center" justifyContent="center" className={classes.cont_icon} >
          {petit === false &&
            <>
              <Grid item md={3}> </Grid>

              <Grid item xs={2}>
                <Tooltip TransitionComponent={Zoom} title="Blog" arrow open={true} placement="top">
                  <img src={blog} className={classes.icone + " hvr-pulse"} alt="Blog" onClick={() => { window.scrollTo(0, 0); history.push(PATHS.BLOG) }} />
                </Tooltip>
              </Grid>


              <Grid item xs={2}>
                <Tooltip TransitionComponent={Zoom} title="Start" arrow open={true} placement="top">
                  <img src={rocket} className={classes.icone + " hvr-pulse"} alt="Start" onClick={() => { window.scrollTo(0, 0); history.push(PATHS.HOME) }} />
                </Tooltip>
              </Grid>

              <Grid item xs={2}>

                <Tooltip TransitionComponent={Zoom} title="Docs" arrow open={true} placement="top">
                  <img src={book} className={classes.doc + " hvr-pulse"} alt="Documentation" onClick={() => { window.scrollTo(0, 0); history.push(PATHS.DOCS) }} />
                </Tooltip>


              </Grid>

              <Grid item md={3}> </Grid>
            </>
          }
          {petit &&
            <>
              <Grid item xs={2}>
                <Tooltip TransitionComponent={Zoom} title="Blog" arrow open={true} placement="top">
                  <img src={blog} className={classes.icone2 + " hvr-pulse"} alt="Blog" onClick={() => { window.scrollTo(0, 0); history.push(PATHS.BLOG) }} />
                </Tooltip>
              </Grid>


              <Grid item xs={2}>
                <Tooltip TransitionComponent={Zoom} title="Start" arrow open={true} placement="top">
                  <img src={rocket} className={classes.icone2 + " hvr-pulse"} alt="Start" onClick={() => { window.scrollTo(0, 0); history.push(PATHS.HOME) }} />
                </Tooltip>
              </Grid>

              <Grid item xs={2}>

                <Tooltip TransitionComponent={Zoom} title="Docs" arrow open={true} placement="top">
                  <img src={book} className={classes.doc2 + " hvr-pulse"} alt="Documentation" onClick={() => { window.scrollTo(0, 0); history.push(PATHS.DOCS) }} />
                </Tooltip>


              </Grid>
            </>
          }

        </Box>
      </div>
      <div >
        <div data-aos="zoom-in" data-aos-anchor-placement="center-bottom">
          <Typography component="h1" variant="h3" className={classes.titre_demo}>
            Démonstration rapide
          </Typography>
        </div>
        <div data-aos="zoom-in" data-aos-anchor-placement="center-bottom">
          <Box display="flex" alignItems="center" justifyContent="center" className={classes.cont_demo}>
            {petit === false &&
              <HtmlTooltip TransitionComponent={Zoom} title={
                <React.Fragment>
                  <Typography color="inherit">Utilisation de 4CAST sur l'interface LEA</Typography>
                </React.Fragment>} arrow placement="right">
                <img src={demo} className={classes.demo} alt="GIF de démo" />

              </HtmlTooltip>
            }
            {petit &&
              <HtmlTooltip TransitionComponent={Zoom} title={
                <React.Fragment>
                  <Typography color="inherit">Utilisation de 4CAST sur l'interface LEA</Typography>
                </React.Fragment>} arrow placement="right">
                <img src={demo} className={classes.demo2} alt="GIF de démo" />

              </HtmlTooltip>
            }
          </Box>
        </div>



      </div>
      <Footer></Footer>
    </>
  );
}

export default Home;
