import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Markdown from './Markdown';
import Button from '@material-ui/core/Button';
import { PropsWithChildren } from 'react';
import React from 'react';


const useStyles = makeStyles((theme) => ({
    markdown: {

        padding: theme.spacing(3, 0),
    },
    boutton: {
        marginBottom: theme.spacing(4),
        backgroundColor: "#4897D8",

    },
    intro: {
        maxHeight: "100"
    }
}));

interface MainProps extends PropsWithChildren<any> {
    posts: Array<string>;
    title: string;
}

export default function Main(props: MainProps) {
    const classes = useStyles();

    const click = (value: string) => {
        props.changement(true, value)
        window.scrollTo(0, 0)
    }



    return (
        <Grid item xs={12} md={12}>
            {
                props.posts.map((post, index) => (
                    <div key={index} data-height="15" >

                        <Markdown className={classes.markdown} key={post.substring(0, 40) + 15}>
                            {post.substring(0, 600)}
                        </Markdown>
                        {
                            post.length > 600 &&
                            <Button className={classes.boutton} onClick={() => click(post)} key={post.substring(0, 10)}>Read More</Button>


                        }
                        <Divider />
                    </div>

                ))
            }
        </Grid >
    );
}