/* eslint-disable react/jsx-pascal-case */
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../hover.css';

import Main from './Main'


const Document = (props: any) => {

    AOS.init();
    var res: string[] = [];
    res.push(props.value)

    return (
        <>
            <Main title="Documentation" posts={res} />
        </>
    );
}

export default Document;
